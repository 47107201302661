import React, { FC, useState, useContext } from 'react'
import { Link } from 'gatsby'

import CartContext from '../../context/cart/CartContext'
import { headerRoutes, megamenuLinks } from '@data/routes/header'

import * as styles from './navbar.module.css'

import MegaMenu from './megamenu'
import Logo from '@components/svg/logo'
import Cart from '@components/svg/cart'

const Navbar: FC = () => {
	const [isNavExpanded, setIsNavExpanded] = useState(false)

	// Extract itemscount from CartContext
	const { itemCount } = useContext(CartContext)

	return (
		<nav className={`${styles.navigation} u-flex`}>
			<div className='u-flex u-flexExpandRight'>
				<Link
					to='/'
					className='u-flex u-flexExpandRight'
					aria-label='Link to homepage of WeChain website'
				>
					<Logo />
				</Link>
				<button
					aria-label='Mobile menu trigger'
					className={styles.hamburger}
					onClick={() => {
						setIsNavExpanded(!isNavExpanded)
					}}
				>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						viewBox='0 0 20 20'
						fill='white'
					>
						<path
							fillRule='evenodd'
							d='M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z'
							clipRule='evenodd'
						/>
					</svg>
				</button>
				<div
					className={`${styles.navigation_menu} ${
						isNavExpanded ? styles.expanded : ''
					}`}
					onClick={() => setIsNavExpanded(!isNavExpanded)}
				>
					<MegaMenu
						header='Produits / Services'
						headerLink='/produits'
						links={megamenuLinks}
					/>
					{headerRoutes
						.filter(route => route.isHeader && !route.dropdown)
						.map((route, index) => {
							return (
								<div key={index} className={styles.navlink}>
									{!route.external ? (
										<Link
											key={index}
											to={`/${route.slug}/`}
											onClick={() => {
												setIsNavExpanded(!isNavExpanded)
											}}
										>
											{route.title}
										</Link>
									) : (
										<a href={route.slug} target='_blank'>
											{route.title}
										</a>
									)}
								</div>
							)
						})}
				</div>
			</div>
			<div
				className={`u-flex u-flexExpandLeft u-flexAlignItemsCenter ${styles.rightCol}`}
			>
				{/* {headerCTA
					.filter(route => route.isHeader)
					.map((route, index) => (
						<Link
							key={index}
							className={`${styles.navlink} ${
								route.className && route.className
							}`}
							to={`/${route.slug}/`}
							onClick={() => {
								setIsNavExpanded(!isNavExpanded)
							}}
						>
							{route.title}
						</Link>
					))} */}
				<Link
					className={`${styles.cart} button_light`}
					to='/panier'
					onClick={() => {
						setIsNavExpanded(!isNavExpanded)
					}}
				>
					<Cart />
					Panier
					{itemCount > 0 && (
						<div>
							<p>{itemCount}</p>
						</div>
					)}
				</Link>
			</div>
		</nav>
	)
}
export default Navbar
