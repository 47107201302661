// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== 'undefined'

const Storage = cartItems => {
	if (isBrowser) {
		localStorage.setItem(
			'cart',
			JSON.stringify(cartItems.length > 0 ? cartItems : [])
		)
	}
}

export const sumItems = cartItems => {
	Storage(cartItems)
	let itemCount = cartItems.reduce(
		(accumulateur, product) => accumulateur + product.quantity,
		0
	)
	let total = cartItems.reduce(
		(total, product) => total + product.price * product.quantity,
		0
	)

	let shipping = total < 1000 ? 75 : 0
	let tvaAmount = Math.round((total + shipping) * 0.2)
	let totalTTC = total + shipping + tvaAmount

	return { itemCount, total, shipping, tvaAmount, totalTTC }
}

const CartReducer = (state, action) => {
	switch (action.type) {
		case 'ADD_ITEM':
			if (state.cartItems.some(item => item.id === action.payload.id)) {
				const item = state.cartItems.find(item => item.id === action.payload.id)
				item.quantity += action.payload.quantity
				item.totalItem = item.quantity * parseInt(action.payload.price)
			} else {
				state.cartItems.push({
					...action.payload,
					totalItem: action.payload.quantity * action.payload.price,
				})
			}

			return {
				...state,
				...sumItems(state.cartItems),
				cartItems: [...state.cartItems],
			}

		case 'REMOVE_ITEM':
			return {
				...state,
				...sumItems(
					state.cartItems.filter(item => item.id !== action.payload.id)
				),
				cartItems: [
					...state.cartItems.filter(item => item.id !== action.payload.id),
				],
			}

		case 'INCREASE':
			state.cartItems[
				state.cartItems.findIndex(item => item.id === action.payload.id)
			].quantity++
			const decreaseItem = state.cartItems.find(
				item => item.id === action.payload.id
			)
			decreaseItem.totalItem =
				decreaseItem.quantity * parseInt(action.payload.price)
			return {
				...state,
				...sumItems(state.cartItems),
				cartItems: [...state.cartItems],
			}

		case 'DECREASE':
			state.cartItems[
				state.cartItems.findIndex(item => item.id === action.payload.id)
			].quantity--
			const increaseItem = state.cartItems.find(
				item => item.id === action.payload.id
			)
			increaseItem.totalItem =
				increaseItem.quantity * parseInt(action.payload.price)
			return {
				...state,
				...sumItems(state.cartItems),
				cartItems: [...state.cartItems],
			}

		case 'CHECKOUT':
			return {
				cartItems: [],
				checkout: true,
				...sumItems([]),
			}

		case 'CLEAR':
			return {
				cartItems: [],
				...sumItems([]),
			}

		default:
			return state
	}
}

export default CartReducer
