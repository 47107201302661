export const categories = [
	{
		name: 'Précoffré®',
		slug: 'precoffre',
		id: 'precoffre',
		links: [
			{
				id: 'manutention_retournement',
				name: 'Levage/Manutention & Désélingage',
				slug: 'levage-manutention-deselingage',
				description: 'Matériel de manutention pour le Précoffré®',
			},
			{
				id: 'retournement',
				name: 'Retournement',
				slug: 'retournement',
				description: 'Matériel de manutention pour le Précoffré®',
			},
			{
				id: 'pose',
				name: 'Pose',
				slug: 'pose',
				description: 'Tout le matériel pour poser le Précoffré®',
			},
			{
				id: 'traitements_joints',
				name: 'Traitements des joints',
				slug: 'traitement-joints',
				description:
					'Outils et matériel pour traitement des joints spécial Précoffré®',
			},
			{
				id: 'traitements_facade',
				name: 'Traitement de façade',
				slug: 'traitement-facade',
				description:
					'Outils et matériel pour traitement de façade spécial Précoffré®',
			},
		],
	},
	{
		name: 'Prestations de chantier',
		slug: 'prestations-chantiers',
		id: 'prestation_chantier',
		links: [
			{
				id: 'location_garde_corps',
				name: 'Location garde-corps',
				slug: 'location-garde-corps',
				description: 'Location garde-corps disponible maintenant',
			},
			{
				id: 'service_technique_chantier',
				name: 'Service Technique chantier / assitance',
				slug: 'service-technique-chantier',
				description: 'Assistance technique sur chantier',
			},
			{
				id: 'prestation_facade',
				name: 'Prestation de façade',
				slug: 'finition-facade',
				description: 'Prestation de finition façade',
			},
		],
	},
]
