import { categories } from './categories'

export const headerRoutes = [
	{
		title: 'Produits',
		slug: 'produits',
		isHeader: true,
		isFooter: true,
		dropdown: true,
		subMenu: [
			{
				title: 'Pose',
				slug: 'pose',
				id: 'pose',
			},
			{
				title: 'Manutention et retournement',
				slug: 'manutention',
				id: 'manutention',
			},
			{
				title: 'Traitement des joints',
				slug: 'joints',
				id: 'joints',
			},
			{
				title: 'Traitement Précoffre®',
				slug: 'precoffre',
				id: 'precoffre',
			},
			{
				title: 'Produits de finition',
				slug: 'finitions',
				id: 'finitions',
			},
		],
	},
	{
		title: 'Livraison',
		slug: 'livraison',
		dropdown: false,
		isHeader: false,
		isFooter: false,
	},
	{
		title: 'Contact',
		slug: 'https://fehrgroup.com/contact',
		external: true,
		dropdown: false,
		isHeader: true,
		isFooter: true,
	},
]

export const headerCTA = [
	{
		title: 'Connexion',
		slug: 'connexion',
		dropdown: false,
		isHeader: true,
		isFooter: true,
		className: 'button_light',
	},
	{
		title: 'Créer compte',
		slug: 'creation-compte',
		dropdown: false,
		isHeader: true,
		isFooter: true,
		className: 'button_green',
	},
]

export const megamenuLinks = [
	...categories,
	{
		name: 'Société',
		slug: 'https://fehrgroup.com',
		target: 'blank',
		links: [
			{
				name: 'FEHR Group',
				slug: 'https://fehrgroup.com',
				id: 'https://fehrgroup.com',
				description:
					"Depuis 1960, FEHR Group est le leader sur le marché du béton, du béton prêt à l'emploi (BPE) et des murs préfabriqués.",
				target: 'blank',
			},
			{
				name: 'Carrières',
				slug: 'https://www.talentdetection.com/fehr/65771-hdMCpqja1C/accueil',
				id: 'Rejoignez notre équipe de passionné(e)s !',
				description: 'Rejoignez notre équipe de passionné(e)s !',
				target: 'blank',
			},
			{
				name: 'La Préfabrication',
				slug: 'https://fehrgroup.com',
				id: 'https://fehrgroup.com',
				description: 'La Préfabrication, qu’est ce que c’est ?',
				target: 'blank',
			},
		],
	},
]
