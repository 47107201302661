import React, { FC } from 'react'
import { Link } from 'gatsby'

import * as styles from './footer.module.css'

import Logo from '@components/svg/logo'
import { headerRoutes } from '@data/routes/header'

const Footer: FC = () => (
	<footer className={styles.footer}>
		<div className='u-flex u-flexCol u-flexAlignItemsCenter'>
			<Logo />
			<div className='u-flex'>
				{headerRoutes
					.filter(route => route.isFooter && !route.external)
					.map(el => (
						<Link key={el.slug} to={`/${el.slug}/`}>
							{el.title}
						</Link>
					))}
				{headerRoutes
					.filter(route => route.isFooter && route.external)
					.map(el => (
						<a key={el.slug} href={el.slug} target='_blank'>
							{el.title}
						</a>
					))}
			</div>
		</div>
		<div className='u-flex u-flexJustifyBetween'>
			<p>© 2077 FEHR Group. All rights reserved.</p>
			<div className='u-flex'>
				<Link to='/conditions-generales-vente'>CGV</Link>
			</div>
		</div>
	</footer>
)

export default Footer
