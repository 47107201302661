import React, { useState } from 'react'
import { Link } from 'gatsby'

import * as styles from './navbar.module.css'

type MegaMenuProps = {
	header: string
	headerLink: string
	links: {
		name: string
		slug?: string
		target: string
		links: {
			name: string
			slug: string
			id: string
			description?: string
			icon?: string
			target: string
		}[]
	}[]
}

const MegaMenu: React.FC<MegaMenuProps> = ({ header, headerLink, links }) => {
	const [isHovered, setIsHovered] = useState(false)

	const handleMouse = () => {
		setIsHovered(!isHovered)
	}

	const handleClose = () => {
		setIsHovered(false)
	}

	return (
		<div
			className={styles.navlink}
			onMouseEnter={handleMouse}
			onMouseLeave={handleMouse}
			onClick={handleClose}
		>
			<Link to={headerLink}>{header}</Link>
			{isHovered && (
				<section className={styles.megamenu}>
					{links?.map((link, i) => (
						<div key={link.name} className={styles.linksWrapper}>
							{!link.target && (
								<Link
									to={`/produits/${link.slug}`}
									className={styles.categorieTitle}
									onClick={handleClose}
								>
									{link.name}
								</Link>
							)}
							{link.target && (
								<a
									href={link.slug}
									className={styles.categorieTitle}
									onClick={handleClose}
									target='_blank'
								>
									{link.name}
								</a>
							)}
							<div className={styles.linksList}>
								{link.links.map((el, i) => (
									<>
										{!el.target && (
											<Link
												key={el.name}
												className={styles.link}
												to={`/produits/${link.slug}/${el.slug}`}
												onClick={handleClose}
											>
												<p className={styles.linkTitle}>{el.name}</p>
												<p className={styles.linkDescription}>
													{el.description}
												</p>
											</Link>
										)}
										{el.target && (
											<a
												key={el.name}
												className={styles.link}
												href={el.slug}
												onClick={handleClose}
											>
												<p className={styles.linkTitle}>{el.name}</p>
												<p className={styles.linkDescription}>
													{el.description}
												</p>
											</a>
										)}
									</>
								))}
							</div>
						</div>
					))}
					<div className={styles.newsWrapper}>
						<img
							src='http://via.placeholder.com/240x100'
							className={styles.img}
						/>
						<div>
							<div>
								<p className={styles.linkTitle}>Notre actualité ! </p>
								<p className={styles.linkDescription}>
									Restez informé des dernières nouvelles.
								</p>
							</div>
						</div>
						<a href='https://fehgroup.com/actualites' target='_blank'>
							Aller sur notre blog
						</a>
					</div>
				</section>
			)}
		</div>
	)
}

export default MegaMenu
