import * as React from 'react'
import type { GatsbyBrowser } from 'gatsby'

import Navbar from './src/components/Navbar/navbar'
import Footer from './src/components/Footer/footer'
import CartState from './src/context/cart/CartState'

import './src/styles/global.css'
import './src/styles/flex.css'
import './src/styles/flex-sm.css'
import './src/styles/flex-md.css'
import './src/styles/flex-lg.css'
import './src/styles/grid.css'

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
	element,
}) => {
	return (
		<CartState>
			<Navbar />
			<main className='main'>{element}</main>
			<Footer />
		</CartState>
	)
}
