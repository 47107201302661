import React, { useReducer } from 'react'
import CartContext from './CartContext'
import CartReducer from './CartReducer'
import { sumItems } from './CartReducer'

const storage =
	typeof window !== `undefined`
		? localStorage.getItem('cart')
			? JSON.parse(localStorage.getItem('cart'))
			: []
		: []

const initialState = {
	cartItems: storage,
	...sumItems(storage),
	checkout: false,
}

const CartState = ({ children }) => {
	const [state, dispatch] = useReducer(CartReducer, initialState)

	const addToCart = payload => {
		dispatch({ type: 'ADD_ITEM', payload })
	}

	const increase = payload => {
		dispatch({ type: 'INCREASE', payload })
	}

	const decrease = payload => {
		dispatch({ type: 'DECREASE', payload })
	}

	const removeProduct = payload => {
		dispatch({ type: 'REMOVE_ITEM', payload })
	}

	const clearCart = () => {
		dispatch({ type: 'CLEAR' })
	}

	const handleCheckout = () => {
		dispatch({ type: 'CHECKOUT' })
	}

	const contextValues = {
		removeProduct,
		addToCart,
		increase,
		decrease,
		clearCart,
		handleCheckout,
		...state,
	}

	return (
		<CartContext.Provider value={contextValues}>
			{children}
		</CartContext.Provider>
	)
}

export default CartState
